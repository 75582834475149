import React, {useEffect, useState, useRef} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {
    displayDate,
    notification, PopConfirm,
} from "../../../components/Elements/appUtils";
import {
    deleteIntakeFxn,
    singleCourseFxn,
    updateCourseFxn,
} from "../action";
import {Card, Form, Table} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";

import {
    conditionOptions,
    typeOfProgramList,
} from "../../../components/_utils/_utils";
import EditIntakeDrawer from "../../intake/editIntakeDrawer";
import UpdateIntakeDrawer from "../../intake/updateIntakeDrawer";

const initState = {
    courseName: "",
    campusName: "",
    courseTraining: "",
    courseDuration: "",
    courseLevel: "",
    courseCategory: "",
    tuitionFee: "",
    payable: "",
    additionalRequirement: "",
    waiver: undefined,
    scholarship: "",
    courseCode: "",
    levelOfStudy: "",
    hoursOfInstructionPerWeek: "",
    hoursOfInstructionContract: "",
    typeOfProgram: "",
    academicStatus: "",
    pathway: undefined,
    programDuration: "",
};

let {FormTitle} = Card;
const EditCourse = (props) => {
    let {_id} = useParams();
    const tableRef = useRef();
    let [intakeState, setIntakeState] = useState({
        visible: false,
    });
    let [addIntakeState, setAddIntakeState] = useState({
        visible: false,
    });
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    const [intakeList, setIntakeList] = useState([]);
    const [intakeData, setIntakeData] = useState([]);
    const [singleIntake, setSingleIntake] = useState("");
    const [intakeId, setIntakeId] = useState("");
    const [pathwayVal, setPathwayVal] = useState("");
    let {form: {getFieldDecorator, getFieldError, setFieldsValue}} = props;

    const apiRequest1 = async () => {
        let {form: {setFieldsValue}} = props;

        let {data} = await dispatch(singleCourseFxn({_id: _id}));
        if (data) {
            setPathwayVal(data.pathway)
            setFieldsValue({
                courseName: data.courseName ? data.courseName : false,
                campusName: data.campusName ? data.campusName : false,
                courseDuration: data.courseDuration,
                courseLevel: data.courseLevel,
                courseCategory: data.courseCategory,
                tuitionFee: data.tuitionFee,
                payable: data.payable,
                additionalRequirement: data.additionalRequirement,
                scholarship: data.scholarship,
                courseCode: data.courseCode,
                levelOfStudy: data.levelOfStudy,
                typeOfProgram: data.typeOfProgram,
                academicStatus: data.academicStatus,
                hoursOfInstructionPerWeek: data.hoursOfInstructionPerWeek,
                hoursOfInstructionContract: data.hoursOfInstructionContract,
                pathway: data.pathway,
                waiver: data.waiver,
                materialFee: data.materialFee,
                applicationFee: data.applicationFee,
                studentArchivingFee: data.studentArchivingFee,
                programDuration: data.programDuration,
                courseTraining: data.courseTraining,
                secondCourseTraining: data.secondCourseTraining
            });

            setIntakeData(data.intakeList || []);
        }
    };
    useEffect(() => {
        apiRequest1();
    }, []);

    const events = {
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        chooseProgram: (value) => {
            events._updateState({typeOfProgram: value});
        },
        reloadTable: () => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        },
        showAddIntake: async () => {
            setAddIntakeState({
                visible: true,
                // pathway: true,
            })
        },
        hideAddIntake: async () => {
            setAddIntakeState({
                visible: false,
                // pathway: false,
            })
        },
        addIntake: async (data) => {
            let existingIntakes = intakeList;
            let updatedIntakes = [...existingIntakes, data];

            let resp = await dispatch(updateCourseFxn({_id: _id, intakeList: updatedIntakes}));
            if (resp.success) {
                notification.success({message: "Intake Added Successfully"});
                setIntakeList(updatedIntakes);
                events.hideAddIntake();
            }
        },
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, valData) => {
            const formattedId = {_id: _id, ...valData, intakeList: intakeData};
            if (!err) {
                let resp = await dispatch(updateCourseFxn(formattedId));
                await apiRequest1()

            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    let sections = [
        {
            title: "Course Information",
            fields: [
                {
                    key: "courseName",
                    label: "Course Name",
                    span: "col-md-6",
                    placeholder: "Enter Course Name",
                    required: true
                },
                {
                    key: "campusName",
                    label: "Campus Name",
                    type: "text",
                    span: "col-md-6",
                    placeholder: "Enter Campus Name",
                },
                {
                    key: "courseDuration",
                    label: "Course Duration",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Course Duration",
                    required: true
                },
                {
                    key: "courseLevel",
                    label: "Course Level",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Course Level",
                    required: true
                },
                {
                    key: "courseCategory",
                    label: "Course Category",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Course Category",
                },
                {
                    key: "tuitionFee",
                    label: "Tuition Fees",
                    type: "number",
                    span: "col-md-4",
                    placeholder: "Enter Tuition Fees",
                    required: true
                },
                {
                    key: "payable",
                    label: "Payable",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Payable",
                },
                {
                    key: "additionalRequirement",
                    label: "Additional Requirements",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Additional Requirements",
                },

                {
                    key: "waiver",
                    label: "Waiver",
                    type: "select",
                    required: true,
                    span: "col-md-4",
                    options: conditionOptions,
                    keyAccessor: (x) => x.name,
                    valueAccessor: (x) => `${x.name}`,
                    onChange: (date) => {
                        setFieldsValue({waiver: date});
                    },
                },

                {
                    key: "scholarship",
                    label: "Scholarship",
                    type: "text",

                    span: "col-md-4",
                    placeholder: "Scholarship",
                },
                {
                    key: "courseCode",
                    label: "Course Code",
                    type: "text",
                    required: true,
                    span: "col-md-4",
                    placeholder: "Course Code",
                },
                {
                    key: "levelOfStudy",
                    label: "Level Of Study",
                    type: "text",

                    span: "col-md-4",
                    placeholder: "Level Of Study",
                },
                {
                    key: "typeOfProgram",
                    label: "Type Of Program",
                    type: "select",
                    required: true,
                    span: "col-md-4",
                    placeholder: "Type Of Program",
                    options: typeOfProgramList,
                    keyAccessor: (x) => x.value,
                    valueAccessor: (x) => `${x.name}`,
                    onChange: (date) => {
                        setFieldsValue({typeOfProgram: date});
                    },
                },
                {
                    key: "academicStatus",
                    label: "Academic Status",
                    type: "text",

                    span: "col-md-4",
                    placeholder: "Academic Status",
                },

                {
                    key: "hoursOfInstructionPerWeek",
                    label: "Hours Of Instruction Per Week",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Hours Of Instruction Per Week",
                },
                {
                    key: "hoursOfInstructionContract",
                    label: "Hours Of Instruction During Contract",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Hours Of Instruction Contract",
                },
                {
                    key: "programDuration",
                    label: "Program Duration",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Program Duration",
                },
                {
                    key: "applicationFee",
                    label: "Application Fee",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Application Fee",
                },
                {
                    key: "materialFee",
                    label: "Material Fee",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Material Fee",
                },
                {
                    key: "studentArchivingFee",
                    label: "Student Archiving Fee",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Student Archiving Fee",
                },
                {
                    key: "pathway",
                    label: "Pathway",
                    type: "select",
                    span: "col-md-4",
                    options: conditionOptions,
                    keyAccessor: (x) => x.value,
                    valueAccessor: (x) => `${x.name}`,
                    onChange: (date) => {
                        setFieldsValue({pathway: date});
                    },
                }
            ],
        },
        {
            title: "Course Training",
            fields: [
                {
                    key: "courseTraining",
                    label: "Course Training",
                    placeholder: "Course Training",
                    type: "textarea",
                    span: "col-md-12",
                    minHeight: 150
                },
                {
                    key: "secondCourseTraining",
                    label: "Course Second Training",
                    placeholder: "Course Second Training",
                    type: "textarea",
                    span: "col-md-12",
                    minHeight: 150
                },
            ],
        },
    ];
    const showIntakeDrawer = async (data) => {
        setSingleIntake(data);
        setIntakeId(data._id);
        setIntakeState({
            visible: true,
        });
    };
    const hideIntakeDrawer = async () => {
        setIntakeState({
            visible: false,
        });
        events.reloadTable()
    };

    const deleteIntakee = async (intakeId) => {
        try {
            const formattedId = {_id: _id, intakeId: intakeId._id};
            let resp = await dispatch(deleteIntakeFxn(formattedId));
            await apiRequest1()
            events.reloadTable()
        } catch (error) {
            notification.error({
                message: error || 'Error'
            })
        }


    }
    const columns = [
        {
            title: "Sr. No.",
            key: "serialNumber",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Month",
            dataIndex: "month",
            key: "month",
        },
        {
            title: "Year",
            dataIndex: "year",
            key: "year",
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (text, record) => {
                return typeof text == "date" ? displayDate(text) : text
            },
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (text, record) => {
                return typeof text == "date" ? displayDate(text) : text
            },
        },
        {
            title: "Second Year Start Date",
            dataIndex: "secondYearStartDate",
            key: "secondYearStartDate",
            render: (text, record) => {
                return typeof text == "date" ? displayDate(text) : text
            },
        },
        {
            title: "Second Year End Date",
            dataIndex: "secondYearEndDate",
            key: "secondYearEndDate",
            render: (text, record) => {
                return typeof text == "date" ? displayDate(text) : text
            },
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    {record && (
                        <>
                            <a className={'btn rounded-pill btn-label-primary btn-sm mr-2'} onClick={() => {
                                showIntakeDrawer(record)
                            }}>
                                <i className="bx bx-edit-alt bx-14px"></i>
                            </a>
                            <PopConfirm
                                title="Are you sure you want to delete this intake?"
                                onConfirm={() => deleteIntakee(record)}>
                                <a className={'btn rounded-pill btn-label-danger btn-sm'}>
                                    <i className="bx bx-trash-alt bx-14px"></i>
                                </a>
                            </PopConfirm>
                        </>
                    )}

                </>
            ),
        },
    ];
    const apiRequest = async (params) => {
        return new Promise(async (resolve) => {
            let {data} = await dispatch(singleCourseFxn({_id: _id}));
            resolve({data: data?.intakeList});
        });
    };
    useEffect(() => {
        apiRequest();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-xl">
                    <Form onSubmit={handleSubmit}>
                        <Card title={"Edit Course"}>
                            <div className="mb-4">
                                {sections.map((section, sectionIndex) => (
                                    <>
                                        <div className="form-section" key={sectionIndex}>
                                            <h6 className="form-title" style={{fontWeight: "bold"}}>
                                                {section.title}
                                            </h6>
                                            <div className="row">
                                                {section.fields.map((item, key) => (
                                                    <div
                                                        className={`${item.span ? item.span : "col-md-6"}`}
                                                        key={key}
                                                    >
                                                        <GetEachFormFields
                                                            getFieldDecorator={getFieldDecorator}
                                                            getFieldError={getFieldError}
                                                            {...props.form}
                                                            item={item}
                                                            keyAccessor={item.keyAccessor}
                                                            valueAccessor={item.valueAccessor}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="my-6 mx-n6"/>
                                    </>
                                ))}
                            </div>


                            <FormTitle name={'Intakes'} btn={(
                                <a className="btn btn-label-primary" onClick={() => {
                                    events.showAddIntake()
                                }}>Manage
                                </a>
                            )}/>
                            <Table apiRequest={apiRequest} ref={tableRef} columns={columns} bordered showView={false}/>
                            <button type="submit" className="btn btn-primary mt-4 ">
                                Submit
                            </button>
                        </Card>
                    </Form>
                    {intakeState.visible ? (
                        <EditIntakeDrawer
                            {...intakeState}
                            singleIntake={singleIntake}
                            pathway={pathwayVal}
                            courseId={_id}

                            intakeId={intakeId}
                            onClose={() => {
                                hideIntakeDrawer();
                            }}
                            onSubmit={() => {
                                hideIntakeDrawer();
                            }}
                        />
                    ) : null}
                    {addIntakeState.visible ? (
                        <UpdateIntakeDrawer
                            {...addIntakeState}
                            pathway={pathwayVal}
                            courseId={_id}
                            onClose={() => {
                                events.hideAddIntake();
                            }}
                            onSuccess={() => {
                                events.reloadTable();
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default Form.create()(EditCourse);
