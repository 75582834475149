import React, {useRef, useState} from 'react';
import {Table} from '../../../components/Elements/appUtils';
import {useDispatch} from "react-redux";
import {appInvoiceListFxn} from "../actions";
import {displayDate} from "../../../components/Elements/appUtils";
import RegenerateInvoiceDrawer from "../../Invoices/drawer/regenerateInvoice";
import InvoicePaymentDrawer from "../../Invoices/drawer/InvoicePayment";
import GenerateInvoiceDrawer from "../../Invoices/add";
import InstallmentHistoryModal from "../../Invoices/drawer/installmentHistory";

const AppInvoiceList = (props) => {
    let {application} = props;
    const [total, setTotal] = useState(0);
    const [visible, setVisible] = useState(false);
    const [regenerateVisible, setRegenerateVisible] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [installmentHistoryState, setInstallmentHistoryState] = useState({
        visible: false,
        invoiceId: ''
    });
    const [payNowState, setPayNowState] = useState({
        invoiceId: '',
        studentId: '',
        visible: false
    });
    const tableRef = useRef()
    const dispatch = useDispatch();

    const events = {
        showPayNowDrawer: async (record) => {
            setPayNowState({
                invoiceId: record.invoices._id,
                studentId: record.studentId,
                visible: true
            });
        },
        hidePayNowDrawer: async () => {
            setPayNowState({
                invoiceId: '',
                studentId: '',
                visible: false
            });
            tableRef.current.reload()
        },
        viewPaymentHistory: (record) => {
            setInstallmentHistoryState({
                visible: true,
                invoiceId: record.invoices._id
            });
        },
        closeInstallmentHistory: () => {
            setInstallmentHistoryState({
                visible: false,
                invoiceId: ''
            });
            tableRef.current.reload()
        }
    };


    const columns = [
        {
            title: 'Sr. No.',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Invoice Date',
            dataIndex: ['invoices', 'invoiceDate'],
            key: 'invoiceDate',
            render: (invoiceDate) => displayDate(invoiceDate),
        },
        {
            title: 'Due Date',
            dataIndex: ['invoices', 'dueDate'],
            key: 'dueDate',
            render: (dueDate) => displayDate(dueDate),
        },
        /*   {
               title: 'Invoice Amount',
               dataIndex: ['invoices', 'invoiceAmount'],
               key: 'invoiceAmount'
           },*/
        {
            title: 'Invoice Amount',
            dataIndex: ['invoices', 'totalOwingNet'],
            key: 'totalOwingNet'
        },
        {
            title: 'Balance',
            dataIndex: ['invoices', 'remainingBalance'],
            key: 'remainingBalance',
            render: (item, record) => (
                <>
                    <div>CAD {item ? item.toFixed(2) : ""}</div>
                    <div className={'mt-3'}>
                        <a className='btn rounded-pill btn-outline-primary btn-xs'
                           onClick={() => events.viewPaymentHistory(record)}>
                            View Payment History
                        </a>
                    </div>
                </>
            )
        },

        /*   {
               title: 'Total Owing Net',
               dataIndex: ['invoices', 'totalOwingNet'],
               key: 'totalOwingNet'
           },*/

        {
            title: 'Invoice',
            dataIndex: ['invoices', 'invoice', 'path'],
            key: 'invoicePath',
            render: (path, record) => (
                <>
                    <a className={'btn rounded-pill btn-primary'} href={path} target={'_blank'}>
                        <i className={'bx bxs-file-pdf'}/>
                    </a>
                    <div className={'mt-2'}>
                        <a
                            className={'btn rounded-pill btn-outline-primary btn-xs'}
                            onClick={() => handleRegenerate(record)}
                        >
                            Re-Generate
                        </a>
                    </div>
                </>
            )
        },
        {
            title: "Status", key: "action",
            render: (text, record) => (
                <>
                    {record.invoices.paymentStatus !== 'Paid' ? (
                        <div className={'mt-2'}>
                            <a className={'btn rounded-pill btn-outline-primary btn-xs'}
                               onClick={() => events.showPayNowDrawer(record)}>Pay Now</a>
                        </div>
                    ) : (
                        <div className={'mt-2'}>
                            <label className="badge bg-label-primary"> Paid </label>
                        </div>
                    )}
                </>
            ),
        },
        {
            title: "Receipt", key: "action",
            render: (text, record) => (
                <>
                    {record.invoices.paymentStatus == 'Paid' && (
                        <div className={'mt-2'}>
                            {record.invoices && record.invoices.receipt && record.invoices.receipt.path ?
                                <a className={'btn rounded-pill btn-outline-primary btn-xs'}
                                   href={record.invoices.receipt.path} target={'_blank'}>
                                    <i className={'bx bxs-file-pdf'}/> Receipt
                                </a> : null}
                        </div>
                    )
                    }
                </>
            )

        }
    ];

    const handleRegenerate = (invoice) => {
        setSelectedInvoice(invoice);
        setRegenerateVisible(true);
    };

    const apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {
            params.applicationId = props.applicationId;
            let resp = await appInvoiceListFxn(params);
            setTotal(resp.total);
            resolve(resp);
        });
    };


    const handleClose = () => {
        setVisible(false);
        setRegenerateVisible(false);
        setSelectedInvoice(null);
    };

    return (
        <>
            <Table apiRequest={apiRequest}
                   columns={columns} bordered showView={false} showPagination={false}
                   ref={tableRef}/>

            {regenerateVisible &&
            <RegenerateInvoiceDrawer
                visible={regenerateVisible}
                onClose={handleClose}
                onSubmit={() => {
                    handleClose()
                    tableRef.current.reload()
                }}
                applicationId={props.applicationId}
                scholarship={application.scholarship || false}
                existingInvoice={selectedInvoice.invoices}
            />
            }
            {payNowState.visible ? (
                <InvoicePaymentDrawer
                    {...payNowState}
                    invoiceId={payNowState.invoiceId}
                    studentId={payNowState.studentId}
                    onClose={() => events.hidePayNowDrawer()}
                    visible={payNowState.visible}
                    applicationId={props.applicationId}
                />
            ) : null}
            {installmentHistoryState.visible && (
                <InstallmentHistoryModal
                    visible={installmentHistoryState.visible}
                    invoiceId={installmentHistoryState.invoiceId}
                    applicationId={props.applicationId}
                    onClose={() => events.closeInstallmentHistory()}
                />
            )}

        </>
    );
};

export default AppInvoiceList;
