import React, {useEffect, useRef, useState} from "react";
import {Card, displayDate, Table} from "../../components/Elements/appUtils";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {invoiceListFxn} from "./actions";
import InvoicePaymentDrawer from "./drawer/InvoicePayment";
import StudentAction from "../student/components/studentAction";
import UserRightComponent from "../../userRights";

const {TableCard} = Card;

const InvoiceList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tableRef = useRef();
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

    const [total, setTotal] = useState(0);
    const [payNowState, setPayNowState] = useState({
        invoiceId: '',
        studentId: '',
        applicationId: '',
        visible: false
    });
    const filterInput = [
        {
            key: "studentId",
            label: "Student Id",
            style: {'height': '20px'},
            span: "col-md-3",
            type: "text"
        },
        {
            key: "status",
            label: " Status",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "firstName",
            label: "First Name",
            span: "col-md-3",
            type: "text"
        },
        {
            key: "courseName",
            label: "Course Name",
            span: "col-md-3",
            type: "text"
        },

        {
            key: "studentType",
            label: "Student Type",
            type: "select",
            options: [
                {label: "International", value: "international"},
                {label: "Onshore", value: "onshore"},
            ],
            span: "col-md-3",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.label
        },
    ];

    const columns = [
        {title: "Sr. No.", key: "serialNumber", render: (text, record, index) => index + 1},
        {
            title: "Student Name",
            dataIndex: ["studentDetails", "firstName"],
            key: "studentName",
            render: (item, record) => {
                return (
                    <StudentAction record={record}/>
                )
            }
        },
        {title: "Student Email", dataIndex: ["studentDetails", "email"], key: "studentEmail"},
        {
            title: "Invoice Date", dataIndex: ["invoices", "invoiceDate"], key: "invoiceDate",
            render: (item) => item ? displayDate(item) : null
        },
        {
            title: "Due Date", dataIndex: ["invoices", "dueDate"], key: "dueDate",
            render: (item) => item ? displayDate(item) : null
        },

        // {title: "Invoice Amount", dataIndex: ["invoices", "invoiceAmount"], key: "invoiceAmount"},
        {title: "Invoice Amount", dataIndex: ["invoices", "totalOwingNet"], key: "totalOwingNet"},
        {
            title: "Balance", dataIndex: ["invoices", "remainingBalance"], key: "remainingBalance", render: (item) => {
                return item ? item.toFixed(2) : ""
            }
        },
        {
            title: "Invoice", dataIndex: ["invoices", "invoice", "path"], key: "invoicePath",
            render: (path) => (
                <a className='btn rounded-pill btn-primary' href={path} target='_blank' rel='noopener noreferrer'>
                    <i className='bx bxs-file-pdf'/>
                </a>
            )
        },

        {
            title: "Status", key: "action",
            render: (text, record) => (
                <>
                    <UserRightComponent route={{right: "accounts", rightType: "view"}} userTypes={['team']}>
                        <>
                            {record.invoices.paymentStatus !== 'Paid' ? (
                                <div className={'mt-2'}>
                                    <a className='btn rounded-pill btn-outline-primary btn-xs'
                                       onClick={() => events.showPayNowDrawer(record)}>Pay Now</a>

                                </div>
                            ) : (
                                <div className={'mt-2'}>
                                    <label className="badge bg-label-primary"> Paid </label>
                                </div>
                            )}
                        </>
                    </UserRightComponent></>

            ),
        },


    ];
    const initialColumns = ['serialNumber', 'studentName', 'studentEmail', 'studentMobile', 'invoiceDate',
        'dueDate', 'remainingBalance', 'totalOwingNet', 'invoiceAmount', 'invoicePath', 'action',];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            const regExFilters = ["firstName", "studentSrId", "status", "courseName", 'studentType'];
            let resp = await dispatch(invoiceListFxn({...params, regExFilters}));
            setTotal(resp.total);
            resolve(resp);
        });
    };

    const events = {
        showPayNowDrawer: (record) => {
            setPayNowState({
                applicationId: record._id,
                invoiceId: record.invoices._id,
                studentId: record.studentId,
                visible: true
            });
        },
        hidePayNowDrawer: () => {
            setPayNowState({
                applicationId: '',
                invoiceId: '',
                studentId: '',
                visible: false
            });
            tableRef.current.reload();
        },
    };

    useEffect(() => {
        apiRequest();
    }, [dispatch]);

    return (
        <>
            <TableCard title={`Invoice List (Total: ${total})`}>
                <Table
                    filterFields={filterInput}
                    initialColumns={initialColumns}
                    apiRequest={apiRequest}
                    columns={columns}
                    bordered
                    ref={tableRef}
                />
            </TableCard>

            {payNowState.visible && (
                <InvoicePaymentDrawer
                    invoiceId={payNowState.invoiceId}
                    studentId={payNowState.studentId}
                    applicationId={payNowState.applicationId}
                    onClose={() => events.hidePayNowDrawer()}
                    visible={payNowState.visible}
                />
            )}
        </>
    );
};

export default InvoiceList;
