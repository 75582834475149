import React, {Suspense, useEffect, useRef, useState} from "react";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import {useDispatch} from "react-redux";
import {addCourseFxn, courseListFxn} from "../action";
import {Card, displayDate, Form, notification, Table} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import IntakeDrawer from "../../intake/intakeDrawer";
import {academicStatusList, conditionOptions, programLevel, typeOfProgramList} from "../../../components/_utils/_utils";

let {FormTitle} = Card;

const initState = {
    courseName: "",
    campusName: "",
    courseDuration: "",
    courseCategory: "",
    tuitionFee: '',
    payable: "",
    additionalRequirement: "",
    scholarship: "",
    courseCode: "",
    courseTraining: "",
    levelOfStudy: "",
    hoursOfInstructionPerWeek: "",
    hoursOfInstructionPerContract: "",
    programDuration: "",
    applicationFee: "",
    materialFee: "",
    studentArchivingFee: ""
};


const AddCourse = (props) => {
    let tableRef = useRef()
    let {form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}} = props
    const dispatch = useDispatch()
    const [state, setState] = useState(initState);
    const [pathwayVal, setPathwayVal] = useState();
    const [intakeList, setIntakeList] = useState([]);
    let [intakeState, setIntakeState] = useState({
        visible: false,
        // pathway: false
    })

    const events = {
        _updateState: (data) => {
            setState((prevState) => ({
                ...prevState,
                ...data,
            }));
        },
        showIntakeDrawer: async () => {
            setIntakeState({
                visible: true,
                // pathway: true,
            })
        },
        hideIntakeDrawer: async () => {
            setIntakeState({
                visible: false,
                // pathway: false,
            })
        },
        addIntake: (data) => {
            setIntakeList((prevIntake) => {
                return [
                    ...prevIntake,
                    data
                ]
            })
            events.hideIntakeDrawer()
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.intakeList = intakeList;
                valData.courseTraining = state.courseTraining;
                let resp = await dispatch(addCourseFxn(valData));
                if (resp && resp.success) {
                    form.resetFields()
                    form.setFieldsValue(initState)
                    setState(initState)
                    setIntakeList([])
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    };

    useEffect(() => {

        setFieldsValue({courseContent: state.courseContent});
        setFieldsValue({courseTable: state.courseTable})
        setFieldsValue({programCosts: state.programCosts})

    }, []);

    let sections = [
        {
            title: "Course Information",
            fields: [
                {
                    key: "courseName",
                    label: "Course Name",
                    span: "col-md-6",
                    placeholder: "Enter Course Name",
                    required: true,

                },
                {
                    key: "campusName",
                    label: "Campus Name",
                    type: "text",
                    span: "col-md-6",
                    placeholder: "Enter Campus Name",
                },
                {
                    key: "courseDuration",
                    label: "Course Duration",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Course Duration",
                    required: true
                },
                {
                    key: "courseLevel",
                    label: "Course Level",
                    type: "select",
                    span: "col-md-4",
                    placeholder: "Enter Course Level",
                    options: programLevel,
                    onChange: (date) => {
                        setFieldsValue({courseLevel: date})
                    },
                    required: true

                },
                {
                    key: "courseCategory",
                    label: "Course Category",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Course Category",
                },
                {
                    key: "tuitionFee",
                    label: "Tuition Fees",
                    type: "number",
                    span: "col-md-4",
                    placeholder: "Enter Tuition Fees",
                    required: true
                },
                {
                    key: "payable",
                    label: "Payable",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Enter Payable",
                },
                {
                    key: "additionalRequirement",
                    label: "Additional Requirements",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Additional Requirements",
                },
                {
                    key: "waiver",
                    label: "Waiver",
                    type: "select",
                    required: true,
                    placeholder: "Select waiver",
                    span: 'col-md-4',
                    options: conditionOptions,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: (date) => {
                        setFieldsValue({waiver: date})
                    }
                },
                {
                    key: "scholarship",
                    label: "Scholarship",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Scholarship",
                },
                {
                    key: "courseCode",
                    label: "Course Code",
                    type: "text",
                    required: true,
                    span: "col-md-4",
                    placeholder: "Course Code",
                    onChange: (data) => {
                        setFieldsValue({courseCode: data})
                    }
                },
                {
                    key: "levelOfStudy",
                    label: "Level Of Study",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Level Of Study",
                },
                {
                    key: "typeOfProgram",
                    label: "Type Of Program",
                    type: "select",
                    span: "col-md-4",
                    placeholder: "Type Of Program",
                    options: typeOfProgramList,
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    onChange: (date) => {
                        setFieldsValue({typeOfProgram: date})
                    },
                    required: true

                },

                {
                    key: "academicStatus",
                    label: "Academic Status",
                    type: "select",
                    span: "col-md-4",
                    placeholder: "Academic Status",
                    options: academicStatusList,
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    onChange: (date) => {
                        setFieldsValue({academicStatus: date})
                    }
                },
                {
                    key: "hoursOfInstructionPerWeek",
                    label: "Hours Of Instruction Per Week",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Hours Of Instruction Per Week",
                },
                {
                    key: "hoursOfInstructionContract",
                    label: "Hours Of Instruction During Contract",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Hours Of Instruction During Contract",
                },
                {
                    key: "programDuration",
                    label: "Program Duration",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Program Duration",
                },
                {
                    key: "applicationFee",
                    label: "Application Fee",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Application Fee",
                },
                {
                    key: "materialFee",
                    label: "Material Fee",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Material Fee",
                },
                {
                    key: "studentArchivingFee",
                    label: "Student Archiving Fee",
                    type: "text",
                    span: "col-md-4",
                    placeholder: "Student Archiving Fee",
                },
                {
                    key: "pathway",
                    label: "Pathway",
                    type: "select",
                    span: "col-md-4",
                    options: conditionOptions,
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    onChange: (date) => {
                        setPathwayVal(date)
                        setFieldsValue({pathway: date})
                    }
                },
            ]
        },
        {
            title: "Course Training",
            fields: [
                {
                    key: "courseTraining",
                    label: "Course Training",
                    placeholder: "Course Training",
                    type: "textarea",
                    span: "col-md-12",
                    minHeight: 150
                },
                {
                    key: "secondCourseTraining",
                    label: "Course Second Training",
                    placeholder: "Course Second Training",
                    type: "textarea",
                    span: "col-md-12",
                    minHeight: 150
                },
            ],
        },

    ]

    const columns = [
        {
            title: 'Sr. No.',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },

        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },
        {
            title: '2nd Year StartDate',
            dataIndex: 'secondYearStartDate',
            key: 'secondYearStartDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },
        {
            title: '2nd Year endDate',
            dataIndex: 'secondYearEndDate',
            key: 'secondYearEndDate',
            render: (data) => {
                return data ? displayDate(data) : null
            }
        },


    ];


    return (
        <div>
            <div className="row">
                <div className="col-xl">
                    <Form onSubmit={handleSubmit}>
                        <Card title={'Add Course'}>
                            <div className="mb-4">
                                {sections.map((section, sectionIndex) => (
                                    <>
                                        <div className="form-section" key={sectionIndex}>
                                            <h6 className="form-title" style={{fontWeight: "bold"}}>
                                                {section.title}
                                            </h6>
                                            <div className="row">
                                                {section.fields.map((item, key) => (
                                                    <div
                                                        className={`${item.span ? item.span : "col-md-6"}`}
                                                        key={key}
                                                    >
                                                        <GetEachFormFields
                                                            getFieldDecorator={getFieldDecorator}
                                                            getFieldError={getFieldError}
                                                            {...props.form}
                                                            item={item}
                                                            keyAccessor={item.keyAccessor}
                                                            valueAccessor={item.valueAccessor}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="my-6 mx-n6"/>
                                    </>
                                ))}
                            </div>

                            <FormTitle name={'Intakes'} btn={(
                                <a className="btn btn-label-primary" onClick={() => {
                                    events.showIntakeDrawer()
                                }}>Manage
                                </a>
                            )}/>
                            <Table dataSource={intakeList} columns={columns} bordered ref={tableRef}/>
                            <button type="submit" className="btn btn-primary mt-5">
                                Submit
                            </button>
                        </Card>
                    </Form>


                </div>
            </div>

            {intakeState.visible ?
                <IntakeDrawer
                    {...intakeState}
                    pathway={pathwayVal}
                    onClose={() => {
                        events.hideIntakeDrawer()
                    }}
                    onSubmit={events.addIntake}
                /> : null}

        </div>
    );
};
export default Form.create()(AddCourse);
